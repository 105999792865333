import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'settings',

    async GET_constants(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/constants`, {params: params}); 
    },

    async saveMultiple(data) {
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/save-multiple`, data);
    },

    async getNamespaceVariables(namespace, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/get-namespace-variables/${namespace}`, {params: params}); 
    },

    async getNamespace(namespace, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/get-namespace/${namespace}`, {params: params}); 
    },

    /**
     * 
     * @param {} comma_namespaces namespace1,namespace2,namespace3 etc.
     * @param {*} params 
     */
    async getNamespaces(comma_namespaces, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/get-namespaces/${comma_namespaces}`, {params: params}); 
    },

    async getItemName(namespace, item, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${namespace}/${item}`, {params: params}); 
    },

    /** 
     * Get all resources 
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params}); 
    }, 
     
    /** 
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {params: params}); 
    }, 

    /** 
     * Create resource
     * POST /resource 
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /** 
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {        
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/${data.id}`, data);
    }, 

    /** 
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
    
}
