<script>
import QueueService from "@/services/QueueService";
import SettingService from "@/services/SettingService"; 

export default {
    name: "VImport",
    data() {
        return {
            loading: false,
            job_id: null,
            result: null,
            last_update: null
        };
    },
    async created() {
        if (!this.$aclGuard(this.$ACL.CONTRACT_MGMT)) {
            return;
        }
        await this.lastImport();
        
    },
    methods: {
        async lastImport() {
            try {
                this.loading = true;
                const r = await SettingService.getNamespace('economic');
                this.last_update = r.data.data;
            } catch (e) {

            }
            this.loading = false;
        },
        async startImport() {
            try {
                this.loading = true;
                const r = await QueueService.createTask("SyncCustomers");
                let task = r.data.data;
                this.job_id = task.job_id;
            } catch (e) {
                this.loading = false;
                this.$swal({
                    icon: "error",
                    text: this.$t("queue.error_during_task_creation"),
                });
            }
        },
        doneImporting(data) {
            this.loading = false;
            this.job_id = null;
            this.result = data.result;
        },
    },
};
</script>

<template>
    <div class="v-customers-index">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="">
                        <h3>{{$t('customers.automatic_import')}}</h3>
                        <p>
                            {{$t('customers.automatic_import_last_run_at_X', {X: $d(new Date( last_update.last_update), 'long')})}}
                        </p>

                        <h3>{{ $t("customers.manual_import_entry_heading") }}</h3>
                        <p>
                            {{ $t("customers.import_entry_text") }}
                        </p>

                        

                        <base-button type="primary" @click="startImport">
                            <i class="far fa-cloud-download"></i>
                            {{ $t("customers.import_customers_button") }}
                        </base-button>

                        <div
                            class="mt-4 alert alert-default alert-outline"
                            v-if="result"
                        >
                            <h3>{{ $t("customers.import_results") }}</h3>
                            <h4>{{ $t("customers.import_created") }}</h4>

                            <ul v-if="result.created.length">
                                <li
                                    v-for="(res, index) in result.created"
                                    :key="index"
                                >
                                    {{ res.name }}
                                    <v-entity-errors
                                        v-if="notEmptyObject(res.errors)"
                                        :errors="res.errors"
                                    />
                                </li>
                            </ul>
                            <p v-else>
                                {{ $t("customers.import_nothing_created") }}
                            </p>

                            <h4>{{ $t("customers.import_updated") }}</h4>
                            <ul v-if="result.updated.length">
                                <li
                                    v-for="(res, index) in result.updated"
                                    :key="index"
                                >
                                    {{ res.name }}

                                    <v-entity-errors
                                        v-if="notEmptyObject(res.errors)"
                                        :errors="res.errors"
                                    />
                                </li>
                            </ul>
                            <p v-else>
                                {{ $t("customers.import_nothing_updated") }}
                            </p>
                        </div>

                        <v-queue
                            :name="$t('customers.importing_customers')"
                            :job_id="job_id"
                            :loading="loading"
                            @task-ready="doneImporting"
                        />
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>